import { useEffect, useState } from 'react'
import styles from './stories.module.scss'
import Svg from 'components/svg/svg'
import Btn from 'components/btn/btn'
import Image from 'components/image/image'
import Layer from 'components/layer/layer'
import Heading from 'components/heading/heading'
import Icon from 'components/icon/icon'
import { cn, getRoute } from 'utils/helpers'
import { get } from 'utils/api'
import Link from 'next/link'

export default function Stories() {
    const [active, setActive] = useState(0)
    const [data, setData] = useState()

    useEffect(() => init(), [])

    const account = data && data[active] ? data[active] : {}
    const url = account ? getRoute('shop', account) + '#apie' : null
    const total = data ? data.length : 0

    const init = async () => {
        const responsive = await get('home-accounts')
        if (responsive && responsive.data && responsive.data.data && responsive.data.data.accounts) {
            setData(responsive.data.data.accounts.filter(i => i.slug !== 'silumos-nesejas'))
            // const sts = responsive.data.data.accounts
            // const silumos = sts.find(i => i.slug === 'silumos-nesejas')
            // if (silumos) {
            //     setData([silumos, ...sts.filter(i => i.slug !== 'silumos-nesejas')])
            // } else {
            //     setData(responsive.data.data.accounts)
            // }
        }
    }

    const handlePrev = () => {
        if (!total) return
        setActive(active > 1 ? active - 1 : total - 1)
    }

    const handleNext = () => {
        setActive(active === total - 1 ? 0 : active + 1)
    }

    return (
        <Layer className={cn(styles.stories, 'mb24')}>
            <div className={styles.storiesWrap}>
                <div className={styles.bg}>
                    <Svg className={styles.top} name="paper-top" />
                    <Svg className={styles.bottom} name="paper-bottom" />
                    <div className={styles.red}>
                        <Image
                            src="/img/red.png"
                            alt="Nepàprastos Lietuvos gamintojų prekės"
                            layout="responsive"
                            width="436"
                            height="404"
                            objectFit="contain"
                        />
                    </div>
                    <div className={styles.green}>
                        <Image
                            src="/img/green.png"
                            alt="Nepàprastos Lietuvos gamintojų prekės"
                            layout="responsive"
                            width="530"
                            height="279"
                            objectFit="contain"
                        />
                    </div>
                </div>
                <div className={styles.content}>
                    <Heading className={styles.heading} tag="h2" size="30">
                        Atrask kūrybiškų žmonių ypatingas prekes
                    </Heading>
                    <Svg className={styles.arrow} name="dashed-arrow" />
                    {account.image && (
                        <>
                            <div className={styles.image}>
                                <div className={styles.img}>
                                    {account.slug === 'silumos-nesejas' ? (
                                        <video loop muted autoPlay playsInline poster={account.story_image}>
                                            <source src="/video/sn.mp4" type="video/mp4" />
                                        </video>
                                    ) : (
                                        <Image
                                            src={account.story_image}
                                            alt={account.name}
                                            layout="fill"
                                            objectFit="cover"
                                        />
                                    )}
                                    <Link href={url}>
                                        <a aria-label={account.name}></a>
                                    </Link>
                                </div>
                            </div>
                            <Icon onClick={handlePrev} className={cn(styles.nav, styles.prev)} icon="left" />
                            <Icon onClick={handleNext} className={cn(styles.nav, styles.next)} icon="right" />
                            <div className={styles.details}>
                                <div className={styles.info}>
                                    <Heading tag="h3" size="24">
                                        <Link href={url}>
                                            <a>{account.name}</a>
                                        </Link>
                                    </Heading>
                                    <p>{account.story_intro}</p>
                                    <Btn size="small" style="yellow" className={styles.more} href={url}>
                                        Plačiau
                                    </Btn>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </Layer>
    )
}

// <div className={styles.logo}>
//     <div className={styles.in}>
//         <div className={styles.holder}>
//             <Image
//                 src={account.image}
//                 alt="Nepàprastos Lietuvos gamintojų prekės"
//                 layout="responsive"
//                 width="530"
//                 height="279"
//                 objectFit="contain"
//             />
//             <Link href={url}>
//                 <a aria-label={account.name}></a>
//             </Link>
//         </div>
//     </div>
// </div>
